import { useForm, ValidationError } from "@formspree/react";

const ContactForm2 = () => {
  const [state, handleSubmit] = useForm("xjkbnyvp");
  if (state.succeeded) {
    return (
      <div className="alert alert-success" role="alert">
        Thank you for your inquiry. We will get back to you soon!
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="form-group form-group-lg">
        <p className="input-group gutter-width-md no-space">
          <span className="gutter-width">
            <label className="form-label-lg before after" htmlFor="name">
              Name
            </label>
            <input name="name" type="text" className="form-lg" id="name" placeholder="Your name here" />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
          </span>

          <span className="gutter-width">
            <label className="form-label-lg before after" htmlFor="email">
              Email *
            </label>
            <input id="email" type="email" name="email" className="form-lg" placeholder="Your email here" required="required" />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </span>
        </p>
      </div>
      <div className="form-group form-group-lg">
        <p className="input-group gutter-width-md no-space">
          <span className="gutter-width">
            <label className="form-label-lg before after" htmlFor="name">
              Phone Number
            </label>
            <input name="phone" type="text" className="form-lg" id="phone" placeholder="Your phone number here" />
          </span>

          <span className="gutter-width">
            <label className="form-label-lg before after" htmlFor="about-work">
              What do you like about our work?
            </label>
            <input id="about-work" name="about-work" className="form-lg" />
          </span>
        </p>
      </div>
      <div className="form-group form-group-lg">
        <p className="input-group gutter-width-md no-space">
          <span className="gutter-width">
            <label className="form-label-lg before after" htmlFor="subject">
              How did you heard about us?
            </label>

            <div className="mt-4">
              <div className="form-check form-check-inline">
                <input type="checkbox" name="facebook" id="checkbox-facebook" value="heard from" />
                <label htmlFor="form-label-lg checkbox-facebook">Facebook</label>
              </div>
              <div className="form-check form-check-inline">
                <input type="checkbox" name="instagram" id="checkbox-instagram" value="heard from" />
                <label htmlFor="form-label-lg checkbox-instagram">Instagram</label>
              </div>
              <div className="form-check form-check-inline">
                <input type="checkbox" name="google" id="checkbox-google" value="heard from" />
                <label htmlFor="form-label-lg checkbox-google">Google</label>
              </div>
              <div className="form-check form-check-inline">
                <input type="checkbox" name="referral" id="checkbox-referral" value="heard from" />
                <label htmlFor="form-label-lg checkbox-referral">Referral</label>
              </div>
              <div className="form-check form-check-inline">
                <input type="checkbox" name="other" id="checkbox-other" value="heard from" />
                <label htmlFor="form-label-lg checkbox-other">Other</label>
              </div>
            </div>
          </span>
          <span className="gutter-width">
            <label className="form-label-lg before after" htmlFor="subject">
              We are looking for...
            </label>

            <div className="mt-4">
              <div className="form-check form-check-inline">
                <input type="checkbox" name="photography" id="checkbox-photography" value="looking" />
                <label htmlFor="form-label-lg checkbox-photography">Photography</label>
              </div>
              <div className="form-check form-check-inline">
                <input type="checkbox" name="videography" id="checkbox-videography" value="looking" />
                <label htmlFor="form-label-lg checkbox-videography">Videography</label>
              </div>
              <div className="form-check form-check-inline">
                <input type="checkbox" name="both" id="checkbox-google" value="photo/video" />
                <label htmlFor="form-label-lg checkbox-google">Both</label>
              </div>
            </div>
          </span>
        </p>
      </div>
      <div className="form-group form-group-lg">
        <label className="form-label-lg before after" htmlFor="message">
          Message
        </label>
        <textarea name="message" className="form-lg" id="message" placeholder="Your message here"></textarea>
      </div>
      <input name="subject" type="hidden" value="New inquiry from website" />
      <input type="text" name="_gotcha" style={{ display: "none" }} />

      <div className="form-group form-group-lg mb-0 text-center">
        <button type="submit" disabled={state.submitting} className="btn btn-outline-primary text-uppercase">
          Send
        </button>
      </div>
    </form>
  );
};

export default ContactForm2;
